import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AppConfig } from '../_common/app.config';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  appConfig: AppConfig;

  constructor(
    private http: HttpClient
  ) { }

  getConfig(): Observable<AppConfig> {
    // if (this.appConfig) {
    //   return of(this.appConfig);
    // } else {
      return this.http.get<AppConfig>('app-config.json?t=' + new Date().getTime())
        .pipe(mergeMap(config => {
          this.appConfig = config;
          return of(config);
        }));
    // }
  }

}
