import { AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { SCREEN_SIZE } from '../screen.size.enum';
import { ResizeService } from '../../_services/resize.service';

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.scss']
})
export class SizeDetectorComponent implements AfterViewInit {
  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs', css: `d-block d-sm-none invisible`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm', css: `d-none d-sm-block d-md-none invisible`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md', css: `d-none d-md-block d-lg-none invisible`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg', css: `d-none d-lg-block d-xl-none invisible`
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl', css: `d-none d-xl-block invisible`
    },
  ];

  @HostListener('window:resize', [])
  private onResize(): void {
    this.detectScreenSize();
  }

  constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) { }

  ngAfterViewInit(): void {
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    const currentSize = this.sizes.find(x => {
      // get the HTML element
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);

      // check its display property value
      const isVisible = window.getComputedStyle(el).display !== 'none';

      return isVisible;
    });

    this.resizeSvc.onResize(currentSize.id);
  }
}
