import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-even-wachten',
  templateUrl: './even-wachten.component.html',
  styleUrls: ['./even-wachten.component.scss']
})
export class EvenWachtenComponent implements OnInit {
  test: boolean;
  onClose: Subject<boolean>;

  constructor(
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public close() {
    this.bsModalRef.hide();
  }


}
