<div *ngIf="config" class="mt-3">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-12">
                        <img class="img-fluid" src="../../assets/img/logo_voice.png">
                    </div>
                    <div class="col-12 mb-3 text-center">
                        <button class="btn btn-success btn-block" type="button" (click)="clickLink()">
                            <span class="film-title">
                                <div *ngIf="nachtjes>0">Morgen om {{config.startDate | date:'HH:mm'}} uur komt hier de Valentijnsshow 2021!</div>
                                <div *ngIf="nachtjes<=0 && getSeconds()>0">Vandaag om {{config.startDate | date:'HH:mm'}} uur komt hier de Valentijnsshow 2021!</div>
                                <div *ngIf="nachtjes===0 && getSeconds()<=0">Druk hier voor de Valentijnsshow 2021!</div>                                
                            </span>
                        </button>
                    </div>
                    <div class="col-12 mb-3 text-center">
                        <button class="btn btn-success btn-block" type="button" (click)="clickLinkKerst()">
                            <span class="film-title">Kijk hier de kerstshow terug</span>
                        </button>
                    </div>
                    <div class="col-12 mt-3">
                        <h4>Geweldig dat u op deze site terecht gekomen bent!</h4>
                        Vanwege de Corona is het dit jaar niet mogelijk geweest om voor de leerlingen van onze
                        Zangstudio leuke optredens te organiseren. De show in het voorjaar werd op het laatste moment
                        afgelast en daarna zijn er geen mogelijkheden meer geweest om optredens te organiseren.<br />
                        Om toch voor onze leerlingen een mogelijkheid te geven hun zangtalent aan familie en bekenden te
                        laten horen hebben we een Kerst Streaming voorgesteld, wat door alle leerlingen geweldig werd
                        gevonden. Met veel enthousiasme hebben ze zich voorbereid om op 20 december 2020 vanaf 18:00
                        uur, onder strikte corona regels, hun nummer via een LIVE stream aan u te laten zien en
                        horen.<br />
                        Vanwege de strikte corona regels zal het programma niet geheel aansluitend zijn, omdat na ieder
                        optreden er zaken ontsmet en opnieuw neergezet moeten worden. Iedere 8 minuten treedt er 1 van
                        onderstaande artiesten op. We hebben zelfs een KERSTMAN die komt zingen. <br />
                        Ga er gezellig voor zitten en zorg voor goed beeld en geluid. <br />
                        <br />
                        Via de link bovenaan deze pagina kunt u de show terugkijken.<br />
                        <br />
                        <!-- <h4>De link werkt vanaf 17:30 uur op 20 december 2020 </h4> -->
                        <br />
                        Veel plezier!!<br />
                        Marian

                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-12">
                        <img class="img-fluid" src="../../assets/img/logo_kerstshow.png">
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let foto of config.fotos; let index=index"
                        class="col-8 offset-2 offset-sm-0 col-sm-6 col-lg-4 my-3">
                        <div
                            [ngClass]="{'rotate-left': isLeft(index, size), 'rotate-right': isRight(index, size),'rotate-none': !isLeft(index, size)&& !isRight(index, size)}">
                            <app-foto-lijst [foto]="foto"></app-foto-lijst>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-12">
                <div class="film-strip m-auto text-center">
                    <div class="film-title">
                        <span #counter>
                            <span *ngIf="nachtjes!==0">{{config.startDate | date:'d LLLL'}} om
                                {{config.startDate | date:'HH:mm'}} uur </span>
                            <span *ngIf="nachtjes===0">Vandaag om {{config.startDate | date:'HH:mm'}} uur </span>
                            <span *ngIf="nachtjes>0">- nog {{nachtjes}} nachtje<span *ngIf="nachtjes!==1">s</span>
                                slapen</span>
                            <span *ngIf="nachtjes<0">- helaas alweer {{nachtjes*-1}} nachtje<span
                                    *ngIf="nachtjes!==-1">s</span> geleden</span>
                        </span>
                    </div>
                    <div class="video">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/YcQqmmBNpl0"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-fluid footer">
        <div class="tree-margin"></div>
        <!-- <div class="pre-tree" [ngStyle]="{'minWidth': getPreTreeWidth(size)}"></div> -->
        <div class="tree"><img src="../../assets/img/footer_tree.png"></div>
        <!-- <div class="tree-margin"></div> -->
    </div>
    <div class="copyright text-center">
        &copy; Copyright {{now | date: 'y' }} Zangstudio The Voice - <a href="https://www.zangstudiothevoice.nl"
            class="text-white" target="_new">www.zangstudiothevoice.nl</a>
    </div>
</div>
<app-size-detector></app-size-detector>