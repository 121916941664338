import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppBootstrapModule } from './helpers/app-bootstrap.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KerstShowComponent } from './kerst-show/kerst-show.component';
import { FotoLijstComponent } from './kerst-show/foto-lijst/foto-lijst.component';
import { SizeDetectorComponent } from './_common/size-detector/size-detector.component';
import { EvenWachtenComponent } from './kerst-show/even-wachten/even-wachten.component';

@NgModule({
  declarations: [
    AppComponent,
    KerstShowComponent,
    FotoLijstComponent,
    SizeDetectorComponent,
    EvenWachtenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppBootstrapModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    EvenWachtenComponent
  ]
})
export class AppModule { }
