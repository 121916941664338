import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-foto-lijst',
  templateUrl: './foto-lijst.component.html',
  styleUrls: ['./foto-lijst.component.scss']
})
export class FotoLijstComponent implements OnInit {
  @Input() foto: string;

  constructor() { }

  ngOnInit(): void {
  }

  getFotoName(foto: string): string {
    if (foto) {
      return foto.substr(0, foto.length - 4);
    }
    return '';
  }

}
