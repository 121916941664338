import { Component, NgZone, OnInit, Renderer2 } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { AppConfigService } from '../_services/app-config.service';
import { AppConfig } from '../_common/app.config';
import { SCREEN_SIZE } from '../_common/screen.size.enum';
import { ResizeService } from '../_services/resize.service';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { EvenWachtenComponent } from './even-wachten/even-wachten.component';

@Component({
  selector: 'app-kerst-show',
  templateUrl: './kerst-show.component.html',
  styleUrls: ['./kerst-show.component.scss']
})
export class KerstShowComponent implements OnInit {
  config: AppConfig;
  nachtjes: number;
  now = Date.now();
  screenSize = SCREEN_SIZE;
  size: SCREEN_SIZE;
  intervalId: number;

  @ViewChild('counter')
  public myCounter: ElementRef;

  constructor(
    private zone: NgZone,
    private renderer: Renderer2,
    private appConfigService: AppConfigService,
    private resizeSvc: ResizeService,
    private modalService: BsModalService
  ) {
    const interval = 1000;
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.renderer.setProperty(this.myCounter.nativeElement, 'textContent', this.getDaysHoursMinutesSeconds());
        setInterval(() => {
          this.renderer.setProperty(this.myCounter.nativeElement, 'textContent'
            , this.getDaysHoursMinutesSeconds());
        }, interval);
      }, 100);
    });
  }

  ngOnInit(): void {
    this.appConfigService.getConfig()
      .subscribe(config => {
        this.config = config;
        this.nachtjes = this.calculateDays(this.config.startDate);
      });
    this.resizeSvc.onResize$.subscribe(x => {
      this.size = x;
    });
    setTimeout(() => {
      this.size = this.resizeSvc.currentSize;
    }, 0);
    this.intervalId = setInterval(() => this.nachtjes = this.calculateDays(this.config.startDate), 1000 * 60);

    setInterval(() => {
      this.appConfigService.getConfig()
        .subscribe(config => {
          this.config = config;
          this.nachtjes = this.calculateDays(this.config.startDate);
        });
    }, 60000);

  }

  getSeconds(): number {
    const dateNow = new Date();
    let seconds = Math.floor((this.config.startDate.getTime() - dateNow.getTime()) / 1000);
    return seconds;
  }

  getDaysHoursMinutesSeconds(): string {
    let seconds = this.getSeconds();
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    var dagen = '';
    if (days !== 0) { dagen = `${days} dag en `; }
    const result = `Nog ${dagen}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return result + `:${seconds.toString().padStart(2, '0')}`;
  }

  calculateDays(dateSent: Date): number {
    const currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor(
      (Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())
        - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24)
    );
  }

  isLeft(index: number, size: SCREEN_SIZE): boolean {
    if (index && (size === SCREEN_SIZE.XL || size === SCREEN_SIZE.LG)) {
      return (index % 3) === 0;
    }
    if (index && (size === SCREEN_SIZE.MD || size === SCREEN_SIZE.SM)) {
      return (index % 2) === 0;
    }
    if (size === SCREEN_SIZE.XS) {
      return (index % 2) === 0;
    }
    return true;
  }

  isRight(index: number, size: SCREEN_SIZE): boolean {
    if (index && (size === SCREEN_SIZE.XL || size === SCREEN_SIZE.LG)) {
      return ((index + 1) % 3) === 0;
    }
    if (index && (size === SCREEN_SIZE.MD || size === SCREEN_SIZE.SM)) {
      return ((index + 1) % 2) === 0;
    }
    if (size === SCREEN_SIZE.XS) {
      return (index % 2) !== 0;
    }
    return false;
  }

  getPreTreeWidth(size: SCREEN_SIZE): string {
    switch (size) {
      case SCREEN_SIZE.XL:
        return `${1140 - 182}px`;
      case SCREEN_SIZE.LG:
        return `${960 - 182}px`;
      case SCREEN_SIZE.MD:
        return `${720 - 182}px`;
      case SCREEN_SIZE.SM:
        return `${640 - 182}px`;
      default:
        return '0px';
    }
  }

  clickLinkKerst(): void {
    location.href = 'https://www.youtube.com/watch?v=irp7VBz-X6g';
  }

  clickLink(): void {
    if (this.getSeconds() > 0) {
      this.msgWachten();
    } else {
      location.href = this.config.link;
    }
  }

  public msgWachten(): void {
    const initialState = {
      test: true
    };
    const modalRef = this.modalService.show(EvenWachtenComponent, { initialState });
  }

}
